/* Carousel Container */
#projects {
    margin-top: 4rem;
    padding: 3rem 0;
    background-color: #f9f9f9;
    text-align: center;
}

/* Section Title */
#projects h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    color: #555;
    margin-bottom: 4rem;
    text-transform: uppercase;
    letter-spacing: 2px;
}

/* Carousel Styles */
.carousel .slide {
    background: none; /* Remove default background */
}

.carousel .carousel .control-arrow, 
.carousel .carousel .control-dots {
    display: none; /* Hide arrows and dots if not needed */
}

.carousel .carousel-status {
    display: none; /* Hide slide counter */
}

/* Project Card */
.project {
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
}

/* Project Title */
.project h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    color: #555;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Project Video */
.project video {
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
}

/* Project Description */
.project-description {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
    line-height: 1.6;
    text-align: center;
}

/* Tools & Libraries Section */
.project h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    color: #555555;
    margin-top: 2rem;
    margin-right: 26rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.project ul {
    margin-left: 2rem;
    text-align: left;
    list-style-type: none;
    padding: 0;
}

.project ul li {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #555;
    margin: 0.5rem 0;
}

/* Button */
.project .button {
    display: inline-block;
    background-color: #555;
    color: white;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    border-radius: 25px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-decoration: none;
    margin-right: 40px;
}

.project .button:hover {
    background-color: #76c781;
    transform: scale(1.05);
}

.github-button {
    background-color: #333;
}

.github-button:hover {
    background-color: #76c781;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .project {
        padding: 1rem;
        max-width: 100%;
    }

    .project h3 {
        font-size: 1.5rem;
    }

    .project-description {
        font-size: 0.9rem;
    }

    .project .button {
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
    }
}
