#skills-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem; 
    padding: 1rem;
}

h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    color: #555;
    margin-top: 4rem;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.skill {
    position: relative;
    text-align: center;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    transition: transform 0.3s ease;
}

.skill-icon {
    font-size: 3rem;
    color: #333;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.proficiency-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid #76c781;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: rotate(0deg);
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.skill:hover .skill-icon {
    transform: rotateY(180deg);
    opacity: 0;
}

.skill:hover .proficiency-circle {
    transform: rotateY(0deg);
    opacity: 1;
}

.proficiency-text {
    font-size: 1.5rem;
    color: #333;
}
